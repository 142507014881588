import { useNavigate, useRevalidator } from '@remix-run/react';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { $path } from 'remix-routes';

import { type DtoLearningGroupWithCounts } from '@lp-lib/api-service-client/public';

import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { getLogger } from '../../../../logger/logger';
import { apiService } from '../../../../services/api-service';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { EditIcon } from '../../../icons/EditIcon';
import { OptionsIcon } from '../../../icons/OptionsIcon';
import { TeamIcon } from '../../../icons/TeamIcon';
import { useTriggerAddMembersToGroupModal } from '../useTriggerAddMembersToGroupModal';
import { useTriggerCreateGroupModal } from '../useTriggerCreateGroupModal';

const logger = getLogger().scoped('LearnersPage');

interface GroupRowItemProps {
  row: DtoLearningGroupWithCounts;
  onAddMembers: (groupId: string) => void;
  onDeleteGroup: (groupId: string) => void;
  onEditGroup: (groupId: string) => void;
}

export function GroupRowItem({
  row,
  onAddMembers,
  onDeleteGroup,
  onEditGroup,
}: GroupRowItemProps) {
  return (
    <div
      className='w-full rounded-lg px-4 py-3 bg-[#17171A] flex items-center hover:bg-[#1f1f22] transition-colors relative border border-lp-gray-003 hover:cursor-pointer'
      onClick={() => onEditGroup(row.id)}
    >
      <div className='flex-1 text-white font-medium text-sm truncate'>
        {row.name || 'Unnamed Group'}
      </div>

      <div className='w-24 text-white/90 text-sm text-center'>
        {row.membersCount}
      </div>

      <div className='w-32 text-white/90 text-sm text-center'>
        {new Date(row.createdAt).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })}
      </div>

      <div className='w-24 text-white/90 text-sm text-center'>
        {row.coursesCount}
      </div>

      {/* Actions Menu */}
      <div className='w-12 flex justify-end'>
        <GroupActionsMenu
          onAddMembers={() => onAddMembers(row.id)}
          onDelete={() => onDeleteGroup(row.id)}
          onEdit={() => onEditGroup(row.id)}
        />
      </div>
    </div>
  );
}

interface GroupActionsMenuProps {
  onAddMembers: () => void;
  onDelete: () => void;
  onEdit: () => void;
}

export const GroupActionsMenu = ({
  onAddMembers,
  onDelete,
  onEdit,
}: GroupActionsMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useOutsideClick(menuRef, () => {
    setShowDropdown(false);
  });

  return (
    <div className='relative' ref={menuRef}>
      <button
        type='button'
        className='p-2 hover:bg-[#232325] rounded-lg transition-colors'
        onClick={(e) => {
          e.stopPropagation();
          setShowDropdown(!showDropdown);
        }}
      >
        <OptionsIcon className='w-5 h-5 fill-current text-white' />
      </button>

      {showDropdown && (
        <div className='absolute right-0 top-12 bg-black text-white rounded-xl p-1 border border-secondary w-45 z-50'>
          <button
            type='button'
            onClick={(event) => {
              event.stopPropagation();
              onEdit();
              setShowDropdown(false);
            }}
            className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms'
          >
            <EditIcon className='w-6 h-6 fill-current text-white pr-2' />
            Edit Group
          </button>
          <button
            type='button'
            onClick={(event) => {
              event.stopPropagation();
              onAddMembers();
              setShowDropdown(false);
            }}
            className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms'
          >
            <TeamIcon className='w-6 h-6 fill-current text-white pr-2' />
            Add Members
          </button>
          <button
            type='button'
            onClick={(event) => {
              event.stopPropagation();
              onDelete();
              setShowDropdown(false);
            }}
            className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms text-red-500'
          >
            <DeleteIcon className='w-6 h-6 fill-current pr-2' />
            Delete Group
          </button>
        </div>
      )}
    </div>
  );
};

export interface GroupPageProps {
  groups?: Nullable<DtoLearningGroupWithCounts[]>;
}

export function GroupPage({ groups }: GroupPageProps) {
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const triggerAddMembers = useTriggerAddMembersToGroupModal();
  const triggerCreateGroup = useTriggerCreateGroupModal();

  const displayedGroups = groups ?? [];

  const handleAddMembers = async (emails: string[], groupId: string) => {
    try {
      await apiService.learning.addMembersToGroup(groupId, { emails });
      toast.success('Members added to group');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to add members', error);
      toast.error('Failed to add members');
    }
  };

  const handleCreateGroup = async ({
    name,
    emails,
  }: {
    name: string;
    emails: string[];
  }) => {
    try {
      await apiService.learning.createGroup({ name, emails });
      toast.success('Group created successfully');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to create group', error);
      toast.error('Failed to create group');
    }
  };

  const handleDeleteGroup = async (groupId: string) => {
    await apiService.learning.deleteGroup(groupId);
    revalidator.revalidate();
  };

  const handleEditGroup = async (groupId: string) => {
    navigate($path('/learning/admin/groups/:id', { id: groupId }));
  };

  return (
    <div className='w-full h-full text-white px-8 py-10'>
      <div className='max-w-screen-2xl mx-auto pb-6'>
        <div className='flex items-center justify-between mb-8'>
          <h1 className='text-3xl font-bold'>
            Groups ({displayedGroups.length})
          </h1>

          <button
            type='button'
            onClick={() => {
              triggerCreateGroup({
                onConfirm: handleCreateGroup,
              });
            }}
            className='btn-secondary px-4 py-2'
          >
            Create Group
          </button>
        </div>

        {/* Header row */}
        <div className='w-full px-4 py-2 flex items-center text-gray-400 text-xs uppercase font-medium'>
          <div className='flex-1'>Name</div>
          <div className='w-24 text-center'>Members</div>
          <div className='w-32 text-center'>Created On</div>
          <div className='w-24 text-center'>Courses</div>
          <div className='w-12' />
        </div>

        <div className='flex flex-col mt-4 text-gray-300 gap-2.5'>
          {displayedGroups.map((g) => (
            <GroupRowItem
              key={g.id}
              row={g}
              onAddMembers={() => {
                triggerAddMembers({
                  group: {
                    id: g.id,
                    name: g.name,
                  },
                  onConfirm: async (emails) => {
                    await handleAddMembers(emails, g.id);
                  },
                });
              }}
              onDeleteGroup={handleDeleteGroup}
              onEditGroup={handleEditGroup}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import {
  GroupPage,
  type GroupPageProps,
} from '../components/Training/Admin/Learner/GroupsPage';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export async function clientLoader(
  action: ClientLoaderFunctionArgs
): Promise<GroupPageProps> {
  const resp = await tokenWithRedirect(
    () => apiService.learning.getUserCreatedGroups(),
    action.request.url
  );

  return {
    groups: resp.data,
  };
}

export function Component() {
  const { groups } = useLoaderData<typeof clientLoader>();
  return <GroupPage groups={groups} />;
}
